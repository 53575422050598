<template>
  <div class="bragingContainer">
    <transition name="fade-transform" mode="out-in">
      <!-- 表单组件 -->
      <div class="form" v-if="pageStatus === 1">
        <status-line :value="activeStatus()" @clickItem="clickItem" :loadingType="createdtable.loadingType" :signType="createdtable.signType" :nominationFormStatus="createdtable.nominationFormStatus" ></status-line>
        <div v-if="showDataForm" class="my-3 flex flex-wrap justify-between gap-2">
          <div class="flex flex-wrap">
            <el-button
                v-if="
              ((createdtable.signType == 2 && createdtable.status == 3) || createdtable.status == 4) && userInfo.role == 3 && !isRequest
            "
                type="primary"
                size="mini"
                @click="handleeResetClick"
            >
              {{ $t("btn.ReserSignature") }}
            </el-button>
            <el-button
                size="mini"
                v-if="showRequestModifyButton() && !isRequest"
                type="primary"
                @click="handleRequestClick"
            >
              {{ $t("btn.rforModification") }}
            </el-button>
            <el-button
                size="mini"
                v-if="showRequestModifyButton() && isRequest"
                class="textBtn"
                type="text"
                @click="handleRequestClick"
            >
              {{ $t("btn.rforModification") }}
            </el-button>
            <!-- <el-button
                v-if="createdtable.status == 2 && userInfo.role == 2 && userInfo.userType == 1"
                type="primary"
                size="small"
                @click="handleCompleteClick"
              >
                {{ $t("btn.Complete") }}
              </el-button> -->
              <el-button
                  size="mini"
                  v-if="showCancelButton()"
                  type="danger"
                  @click="handleRequestClick"
              >
                {{ $t("btn.cancelBargingNomination") }}
              </el-button>
              <el-button
                  size="mini"
                  v-if="showPreviewButton()"
                  type="primary"
                  @click="handleBookPreviewPdf(bid)"
              >
                Print Barge Information List
              </el-button>
            </div>
            <div class="flex flex-wrap">
              <!--   :loading="subbtns" -->
              <el-button
                  type="primary"
                  v-if="submitbtn && $store.state.userInfo.role != 4"
                  size="mini"
                  @click="handleSubmitClick"
              >
                {{
                  (this.$route.query.id && this.userType == 2) ||
                  this.userType == 4
                      ? $t("btn.save")
                      : $t("btn.submit")
                }} 
              </el-button>
              <el-button
                  id="logisticsSaveEstimatedBerthTimingBtn"
                  size="mini"
                  v-if="showLogisticsSaveButton()"
                  type="primary"
                  @click="handleSubmitClick"
              >
                Save
              </el-button>
              <!-- <el-button
                  type="primary"
                  :loading="subbtns"
                  v-if="sdocumentbtn"
                  size="small"
                  @click="handleSendClick"
              >
                {{ $t("btn.SendDocument") }}
              </el-button> -->
              <!-- <el-button
                type="primary"
                :loading="subbtns"
                v-if="sdocumentbtn"
                size="small"
                @click="handleSendClick"
            >
              {{ $t("btn.SendDocument") }}
            </el-button> -->
            <!-- <el-button
              type="primary"
              :loading="subbtns"
              v-if="signsubmit"
              size="small"
              @click="handlsignsubmitClick"
            >
              {{
                this.signcersubmit == 0
                  ? $t("btn.signasubmit")
                  : $t("btn.signOfsubmit")
              }}
            </el-button> -->
            <el-button
                type="primary"
                :loading="subbtns"
                size="mini"
                v-if="
                  signcersubmit == 0 &&
                  signsubmit &&
                  createdtable.signType == 1 &&
                  userInfo.role == 3 &&
                  show
                "
                @click="handlsignsubmitClick"
            >
              {{ $t("btn.signasubmit") }}
            </el-button>
            <el-button
                type="primary"
                :loading="subbtns"
                size="mini"
                v-if="
                    signcersubmit == 1 &&
                    createdtable.signType == 2 &&
                    signsubmit
                  "
                @click="handlsignsubmitClick"
            >
              {{ $t("btn.signOfsubmit") }}
            </el-button>
            <el-button plain size="mini" type="primary" @click="fromclose">
              {{ $t("btn.close") }}
            </el-button>
          </div>
        </div>
        <div v-if="showDataForm" class="flex flex-col xl:flex-row">
          <div class="xl:mr-1 w-full pr-2 pl-2" style="border-radius: 10px;background-color:white;">
            <el-tabs
                v-if="showTab"
                v-model="activeTab"
                @tab-click="handletabClick"
            >
              <el-tab-pane :label="$t('menu.bn')" name="bn"></el-tab-pane>
              <el-tab-pane
                  v-if="shouldDisplayCertificates"
                  :label="$t('common.Certificates')"
                  name="Certificates"
              >
              </el-tab-pane>
            </el-tabs>
            <div
                v-else
                style="
                background: #ffffff;
                box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
                border-radius: 2px 2px 0px 0px;
                font-weight: 700;
                font-size: 15px;
                line-height: 18px;
                color: #4c565c;
                padding: 15px 20px;
              "
            >
              Barging Nomination
            </div>
            <div style="border-top: 4px solid #fafafa"></div>
            <barging-nomination-form
                v-show="activeTab == 'bn'"
                ref="bargingForm"
                :createdtable="createdtable"
                :terminaldatas="terminaldatas"
                @shipinfos="shipinfos"
                @upup="upup"
                @loading="loading"
            />
            <certificates
                :statu="statu"
                :bid="bid"
                v-show="activeTab == 'Certificates'"
                :sign_type="createdtable.signType"
                @lists="lists"
                ref="Listcertificates"
            />
          </div>
          
          <div class="mt-2 xl:mt-0 xl:ml-1 flex flex-col justify-between h-full w-full">
            <div class="w-full pr-2 pl-2" style="border-radius: 10px;background-color:white;">
              <ship-tab-view
                ref="shipTabView"
                :shipinfo="shipinfo"
                :vesselName="createdtable.vesselName"
                :status="createdtable.status"
              />
            </div>
            
            <div class="mt-2">
              <div
                  :class="['gap-2 grid',(userType == 1 && !isMobileScreen ) ? 'grid-cols-2' : 'grid-cols-1'
                ]"
                  style="height: 100%"
              >
                <div class="w-full pr-2 pl-2" style="border-radius: 10px;background-color: white;">
                  <time-line :createdtable="createdtable" ref="operationForm" />
                </div>
                <div class="w-full pr-2 pl-2" style="border-radius: 10px;background-color: white;">
                  <jetty-schedule ref="jettyForm" v-if="userType == 1" />
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <barge-form v-if="!showDataForm" :data="createdtable" :showAfter="showFormObj.showAfter"
                    :showBefore="showFormObj.showBefore" :status="activeStatus()" @update="getDetail(bid)"></barge-form>
        <success-dialog ref="successDialog" @upup="upup"/>
        <!-- 码头角色路口 -->
      </div>
      <!-- 码头显示的子组件 -->
      <!-- <terminal v-if="pageStatus === 2" @upup="upup" /> -->
      <!-- 订单列表 -->
      <order-list
          v-if="pageStatus === 0"
          @terminaldata="terminaldata"
          @upindex="upindex"
          ref="orderListRef"
      />
    </transition>
    <sign-document-dialog
      ref="documentdialog"
      :bid="bid"
      :docs="docs"
      :vesselName="createdtable.vesselName"
    />
    <!-- 撤回按钮弹窗 -->
    <revocationDialog
      ref="revocationsDialog"
      :createdtable="createdtable"
      @revocat="revocat"
      :isMobileScreen="isMobileScreen"
    />
    <Senddocumentdialog ref="senddocumentdialog" @upup="upup" />
    <!-- jput重置弹窗 -->
    <juput-reset-dialog ref="juptResetDialog" :bid="bid" />
    <pdf-view
      title="Barging Information List"
      :base64="pdfBase64"
      v-model="showPdfView"
    />
  </div>
</template>

<script>
import SuccessDialog from "@/components/common/success-dialog.vue";
import revocationDialog from "./components/revocation-dialog.vue";
import {mapState} from "vuex";
import bargingNominationForm from "./components/barging-nomination-form.vue";
import Certificates from "./components/certificates.vue";
import JettySchedule from "./components/jetty-schedule.vue";
import ShipTabView from "./components/ship-tab-view.vue";
import TimeLine from "./components/time-line.vue";
import SignDocumentDialog from "./components/sign-document-dialog.vue";
import terminal from "@/components/common/terminal-ops.vue";
import {formall, getUserInfo, previewPdf} from "@/services/user";
import {completeBargingNormination, boatListFile} from "@/services";
import OrderList from "./components/order-list.vue";
import Senddocumentdialog from "@/components/common/Send-document-dialog.vue";
import JuputResetDialog from "./components/juput-reset-dialog.vue";
import PdfView from "@/components/common/pdf-view.vue";
import StatusLine from "@/view/barging/components/statusLine.vue";
import BargeForm from "@/view/barging/components/bargeForm.vue";

export default {
  components: {
    BargeForm,
    StatusLine,
    bargingNominationForm,
    ShipTabView,
    TimeLine,
    JettySchedule,
    Certificates,
    SuccessDialog,
    SignDocumentDialog,
    revocationDialog,
    terminal,
    OrderList,
    Senddocumentdialog,
    JuputResetDialog,
    PdfView
  },
  name: "Barging",
  watch: {
    "$route.query": {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.showFormObj = {}
        this.createdtable = {}
        this.bid = "";
        if (this.bid == "") {
          this.submitbtn = true;
        }
        this.hasShowRequestCancel = false;
        if (newVal.pageStatus != undefined) {
          this.pageStatus = +newVal.pageStatus;
        } else {
          this.pageStatus = 0;
        }
        if (this.$route.query.type == "OK") {
          this.activeTab = "bn";
          this.submitbtn = true;
        }
        if (this.$route.query.id) {
          this.bid = +this.$route.query.id;
          this.getDetail(this.bid);
        }
        // if (
        //   this.$store.state.userInfo.role == 2 &&
        //   this.$store.state.userInfo.userType == 1
        // ) {
        //   this.submitbtn = true;
        // } else if (this.$store.state.userInfo.userType == 1) {
        //   this.submitbtn = false;
        // }

        // if (
        //   this.status == 1 &&
        //   this.userType == 1 &&
        //   this.$store.state.userInfo.role == 2
        // ) {
        //   this.submitbtn = false;
        // }
        if (this.status == 3) {
          this.submitbtn = true;
          this.status == 0;
        }
      },
    },
    locale() {
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
    pageStatus(){
      // if(this.pageStatus == 0){
      //   if(this.upindex != this.$refs.orderListRef.orderList.length){
      //   }
      // }
    }
  },
  data() {
    return {
      docs: [],
      showJetty: false,
      cancelBargingNominationButton: true,
      showList: true,
      activeTab: "bn",
      refresh: true,
      formdatas: {},
      userType: "",
      terminalvisible: false,
      listVisible: false,
      terminaldatas: {},
      // 头部驳回按钮
      cancelBargingBtn: false,
      //头部提交按钮
      submitbtn: true,
      // 头部link提交按钮
      signsubmit: false,
      // 0 列表页面 1 表单页面 2 码头列表页面
      pageStatus: 0,
      createdtable: {},
      // 签名弹窗
      documentvisible: false,
      // form的tabs控制
      Certifvisible: false,
      bid: "",
      signcersubmit: 0,
      statu: 1,
      shipinfo: {},
      // 用户进来后的初始状态
      userInfos: {},
      CommitBtnvisible: true,
      subbtns: false,
      // 提交签名按钮的船舶状态
      shipstats: 0,
      // reason
      reasons: "",
      sdocumentbtn: false,
      showEditForm: false,
      send: 0,
      // link 文件的数量
      quantity: 0,
      quality: 0,
      show: false,
      status: 0,
      hasShowRequestCancel: false,
      hasShowRequest: false,
      showPdfView: false,
      pdfBase64: null,
      showFormObj: {},
      isCertificatesTab: false,
    };
  },
  computed: {
    ...mapState(["locale", "userInfo","screenType"]),
    isRequest() {
      return this.createdtable.requestStatus == 1 && this.createdtable.status != 4;
    },
    showTab() {
      return (
        ((this.createdtable.status == 2 ||
          this.createdtable.status == 3 ||
          this.createdtable.status == 4) &&
          this.userInfo.userType == 1) ||
        this.userInfo.userType == 4 || this.userInfo.userType == 2
      );
    },
    shouldDisplayCertificates() {
      return process.env.VUE_APP_SHOW_CERTIFICATES == "true" || process.env.VUE_APP_SHOW_CERTIFICATES === true;
    },
    isMobileScreen(){
      return this.screenType?.isMobileScreen ?? false;
    },
    showDataForm() {
      let isShow = false
      if (this.createdtable.id) {
        if (this.activeStatus() === '-1' || this.activeStatus() === '0' || this.activeStatus() === '1' || this.activeStatus() === '2null' || this.activeStatus() === '2-8' || this.activeStatus() === '4' || this.activeStatus() === '3' || this.activeStatus() === '3-2' || this.activeStatus() === '3-3') {
          isShow = true
        } else {
          isShow = false
        }
        if (this.activeStatus() === '2-8' || this.activeStatus() === '3' || this.activeStatus() === '3-2' || this.activeStatus() === '3-3') {
          this.activeTab = 'Certificates'
          isShow = true
          this.handletabClick({index: '1'})
        } else {
          this.activeTab = 'bn'
          this.handletabClick({index: '0'})
        }
        if (this.showFormObj.showEdit) {
          isShow = true
          this.activeTab = 'bn'
          this.handletabClick({index: '0'})
        }
        if (this.showFormObj.showPdf) {
          this.activeTab = 'Certificates'
          this.handletabClick({index: '1'})
        }
        if (this.showFormObj.showBefore || this.showFormObj.showAfter) {
          isShow = false
        }
        if (!this.createdtable.id) {
          isShow = true
        }
      } else {
        isShow = true
      }
      return isShow
    },
  },
  mounted() {
    this.getUserInfo();
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs.orderListRef) {
      this.$refs.orderListRef.handleStopRefresh()
    }
    next()
  },
  methods: {
    activeStatus() {
      let val = '-1'
      if (this.createdtable.id) {
        if (this.createdtable.status === 0) {
          val = '0'
        }
        if (this.createdtable.status === 1) {
          val = '1'
        }
        if (this.createdtable.status === 2) {
          val = '2null'
          if (this.createdtable.loadingType === 0) {
            val = '2'
          }
          if (this.createdtable.loadingType === 1) {
            val = '2-1'
          }
          if (this.createdtable.loadingType === 2) {
            val = '2-2'
          }
          if (this.createdtable.loadingType === 3) {
            val = '2-3'
          }
          if (this.createdtable.loadingType === 4) {
            val = '2-4'
          }
          if (this.createdtable.loadingType === 5) {
            val = '2-5'
          }
          if (this.createdtable.loadingType === 6) {
            val = '2-6'
          }
          if (this.createdtable.loadingType === 7) {
            val = '2-7'
          }
          if (this.createdtable.loadingType === 8) {
            val = '2-8'
          }
        }
        if (this.createdtable.status === 3) {
          if (this.createdtable.signType === 0 || this.createdtable.signType == null) {
            val = '2-8'
          }
          if (this.createdtable.signType === 1) {
            val = '3'
          }
          if (this.createdtable.signType === 2) {
            val = '3-2'
          }
          if (this.createdtable.signType === 3) {
            val = '3-3'
          }
        }
        if (this.createdtable.status === 4) {
          val = '4'
        }
      }
      return val
    },
    clickItem(obj) {
      this.showFormObj = {
        ...obj
      }
    },
    showRequestModifyButton() {
      return this.createdtable.status == 4 && this.userInfo.role == 5;
    },
    showCancelButton() {
      return (
          (this.createdtable.status == 0 ||
              this.createdtable.status == 1 || 
              this.isJettyAssigned() || 
              this.isBooked() ) &&
          this.userInfo.role == 5 &&
          !this.hasShowRequestCancel &&
          this.bid
      );
    },
    showPreviewButton() {
      return (
          (this.createdtable.status == 2 ||
              this.createdtable.status == 3 ||
              this.createdtable.status == 4) &&
          this.bid && 
          (this.userInfo.userType == 1 && this.userInfo.role=='2')
      );
    },
    showLogisticsSaveButton() {
      // req 302: let logistics can save Estimated Berth Timing, ccr cannot
      return (
        this.userInfo.role == 1 && 
        (this.createdtable.status == 1 || this.createdtable.status == 2) &&
        this.bid &&
        !this.isCertificatesTab
      );
    },
    handleeResetClick() {
      this.$refs.juptResetDialog.open();
    },
    getDetail(id) {
      formall(id).then((res) => {
        this.$nextTick(() => {
          this.createdtable = res.data;
          this.userType = this.$store.state.userInfo.userType;
          this.userInfos = this.$store.state.userInfo;
          let val = this.createdtable.status;
          // this.upindex(val)
          this.checkSaveButton(val);
        });
      });
    },
    getUserInfo() {
      this.userType = this.$store.state.userInfo.userType;
      this.userInfos = this.$store.state.userInfo;
      // 1是码头
      if (this.userType == 1) {
        this.submitbtn = true;
        // this.terminalvisible = true;
      }
      this.showJetty = this.userType === 1;
    },
    handleSubmitClick() {
      this.$refs.bargingForm.submitForm(() => {
        this.subbtns = true;
      });
    },
    handleSubmitFreshWaterAvailableClick() {
      this.$refs.bargingForm.handleSubmitFreshWaterAvailable(() => {
        this.subbtns = true;
      });
    },

    handleSendClick() {
      this.$refs.senddocumentdialog.open();
    },

    handlsignsubmitClick() {
      this.$refs.documentdialog.open();
    },
    handlBookClick() {
      this.$refs.bargingForm.handleBook(() => {
        this.loading(1);
        this.upup('4');
        this.$message.success('Book Success')
        this.subbtns = true;
      });
    },
    checkSaveButton(status) {
      if (status <= 2) {
        this.submitbtn = true;
      }
      // customer
      if (this.userInfo.userType == 2) {
        if (status == 0 || status == 1 || (status == 2 && this.createdtable.blockNomination < 2) ) {
          this.submitbtn = true;
        } else {
          this.submitbtn = false;
        }
      }
      // juput
      if (this.userInfo.userType == 1) {
        if ((status == 1 || this.isJettyAssigned() || this.isBooked() ) &&
          this.$store.state.userInfo.role == 2 &&
          this.activeTab == 'bn') {
          this.submitbtn = true;
        } else {
          this.submitbtn = false;
        }
      }
      // brage
      if (this.userInfo.userType == 4 && this.userInfo.role == 8) {
        if (status == 0 || status == 1 || (status == 2 && this.createdtable.blockNomination < 2) ) {
          this.submitbtn = true;
        } else {
          this.submitbtn = false;
          this.signcersubmit = 1;

        }
      }
      if (status == 3 || status == 4 || this.userInfo.userType == 3) {
        this.submitbtn = false;
        this.signsubmit = false;
      }
      if(this.userInfo.userType == 0){
        this.submitbtn = false;
      }
    },
    handleCancelClick() {},
    // 点击tbs栏的index
    upindex(val) {
      this.signcersubmit = 0;
      if (val == 2 && this.userType == 1) {
        this.Certifvisible = true;
      } else if (val == 3 && this.userType == 1) {
        this.Certifvisible = true;
        this.submitbtn = false;
      } else if (val == 2 && this.userType == 4) {
        this.signcersubmit = 1;
        this.statu = 4;
        this.Certifvisible = false;
        this.submitbtn = false;
        this.signsubmit = false;
      } else if (val == 3 && this.userType == 4 && this.userInfo.role == 8) {
        this.signcersubmit = 1;
        this.Certifvisible = true;
        this.submitbtn = false;
        this.shipstats = 0;
        this.statu = 4;
      } else if (val == 4 && this.userType == 1) {
        this.Certifvisible = true;
        this.submitbtn = false;
        this.sdocumentbtn = true;
        this.send = 1;
      } else if (val == 4) {
        this.submitbtn = false;
        this.signsubmit = false;
        this.Certifvisible = true;
      } else if (val == 5) {
        this.signcersubmit = 1;
        this.shipstats = 2;
        this.Certifvisible = false;
        this.submitbtn = false;
        this.signsubmit = false;
        this.statu = 4;
        this.cancelBargingBtn = false;
      } else {
        this.Certifvisible = false;
      }
      if (this.userTy) {
        this.Certifvisible = true;
      }
      if (val == 3 || val == 4) {
        this.submitbtn = false;
      }
      this.checkSaveButton(val);
      // // 控制码头除 ops 外  修改按钮的隐藏
      // if (
      //   ((val == 0 || val == 1 || val == 2) &&
      //     this.$store.state.userInfo.role == 2 &&
      //     this.userType == 1) ||
      //   this.userType == 2 ||
      //   this.userType == 4
      // ) {
      //   this.submitbtn = true;
      // } else {
      //   this.submitbtn = false;
      // }
      // // 控制船  修改按钮的隐藏
      // if ((val == 0 || val == 1) && this.userType == 4) {
      //   this.submitbtn = true;
      // } else {
      //   this.submitbtn = false;
      // }
      if (
        (val == 3 || val == 4 || val == 5) &&
        this.userType == 1 &&
        this.$store.state.userInfo.role == 2
      ) {
        this.status = 1;
      }
      if (val == 1 && this.userType == 2) {
        this.status = 3;
      }
      if (val != 4) {
        this.cancelBargingBtn = false;
      }
    },
    // 回退
    fromclose() {
      // this.signcersubmit = false;
      this.sdocumentbtn = false;
      this.activeTab = "bn";
      this.signsubmit = false;
      this.pageStatus = 0;
      this.CommitBtnvisible = true;
      this.send = 0;
      this.status = 0;
      this.$router.back();
      // fix back error
      setTimeout(() => {
        if (this.$route.path == "/barging/index" && this.$route.query.id) {
          this.$router.push({ path: "/barging/index" });
        }
      }, 500);
    },
    // 跳转判断在这里做
    upup(val) {
      if (this.userType == 4) {
        // this.$emit("upup", "4");
        this.$refs.operationForm.getlist();
      }
      if (val == 1) {
        this.$refs.operationForm.getlist();
        this.$refs.jettyForm.getList();
      }
      if (val == 2) {
        this.$refs.operationForm.getlist();
      }
      this.getDetail(this.bid)
    },
    // 码头点击表单过来的数据
    terminaldata(val) {
      if (val.userType == 1) {
        this.submitbtn = false;
        this.refresh = true;
        this.quantityvisible = false;
        this.terminaldatas = val.row;
      }
    },

    // 表单顶头的tab切换
    handletabClick(tab, event) {
      let orderStatus = this.createdtable.status;
      this.signsubmit = true;
      if (tab.index == 1) {
        this.isCertificatesTab = true;
        if (this.userType == 1) {
          this.signcersubmit = 0;
        }
        this.submitbtn = false;
        this.signsubmit = true;
        if (this.userType == 4) {
          if (this.shipstats == 2) {
            this.submitbtn = false;
            this.signsubmit = false;
          }
        }
      } else {
        this.isCertificatesTab = false;

        if (this.userType == 4) {
          if (orderStatus != 1) {
            this.signsubmit = false
          }
          if (orderStatus == 1 || orderStatus == 0) {
            this.submitbtn = true
          }
        }
        if (this.userType == 1) {
          this.signsubmit = false;
          this.submitbtn = ((orderStatus == 1 || this.isJettyAssigned() || this.isBooked()) && this.$store.state.userInfo.role == 2 && this.activeTab == 'bn')
          if (this.send == 1) {
            this.signsubmit = false;
            this.send = 0;
          }
        }

        // Fix 'Certificates' tab switch to 'Barging Nomination' tab but Submit/Save Btn not show
        const isCreating = this.isObjectEmptyOrUndefined(this.createdtable)
        const isCreated = this.activeStatus() == '0'
        const isBargeReady = this.activeStatus() == '1'
        const isJettyAssigned = this.activeStatus() == '2null'
        const isAfterLoadingTerminalSigned = this.activeStatus() == '2-6'
        if (this.userType == 2 && (isCreating || isCreated || isBargeReady || isJettyAssigned)){
          this.submitbtn = true
        }
      }
      if (orderStatus == 3 || orderStatus == 4) {
        this.submitbtn = false //this.allowLoadingFormSubmission();
      }
    },
    isObjectEmptyOrUndefined(obj) {
      return obj === undefined || obj === null || (typeof obj === 'object' && Object.keys(obj).length === 0);
    },
    // form模糊查询传值
    shipinfos(val) {
      this.shipinfo = val;
    },

    handleCompleteClick() {
      completeBargingNormination(this.createdtable.id).then(() => {
        this.$message.success("Barging Normination Completed")
        this.getDetail(this.bid);
        this.$refs.operationForm.getlist();
      })
    },

    handleBookPreviewPdf(id) {
      if( this.checkBookButton()) {
        this.handlBookClick()
      } 
      this.handlePreviewPdf(id)
    },

    handlePreviewPdf(id) {
      previewPdf(id).then(res => {
        this.pdfBase64 = "data:application/pdf;base64," + res;
        this.showPdfView = !this.showPdfView;
      });
     
    },

    handleRequestClick() {
      this.$refs.revocationsDialog.message =
          "Confirm to cancel barging nomination?";
      this.$refs.revocationsDialog.label = "Reason for cancelling";
      if (this.createdtable.status == 4) {
        this.$refs.revocationsDialog.message = "Request for Modiflcation";
        this.$refs.revocationsDialog.label = "Reason";
        this.$refs.revocationsDialog.confirmvisible = true;
      }

      this.$refs.revocationsDialog.show = true;
      if (this.isRequest) {
        this.$refs.revocationsDialog.formDate.reason = this.createdtable.reason;
        this.$refs.revocationsDialog.confirmvisible = false;
        if (this.reasons) {
          this.$refs.revocationsDialog.formDate.reason = this.reasons;
        }
      }
    },
    // 撤回弹窗的值
    revocat(val) {
      this.cancelBargingBtn = false;
      this.has;
      this.submitbtn = false;
      if (val?.reason) {
        this.reasons = val.reason;
        this.CommitBtnvisible = false;
      }
      if (val) {
        this.hasShowRequestCancel = true;
      }
      if (!this.isRequest) {
        this.createdtable.requestStatus = 1;
      }
      this.$refs.operationForm.getlist();
    },
    loading(val) {
      if (val) {
        this.subbtns = false;
      }
    },
    lists() {
      this.quantity = this.$refs.Listcertificates?.quantityTableData.length;
      this.quality = this.$refs.Listcertificates?.qualityTableData.length;
      if ((this.quantity > 0) && this.quality > 0) {
        this.docs = [...this.$refs.Listcertificates?.quantityTableData, ...this.$refs.Listcertificates?.billOfLadingTableData];
        this.show = true;
        this.getDetail(this.bid);
      } else {
        this.show = false;
      }
    },
    checkBookButton() {
      return this.userInfo.userType == 1 && 
             this.$store.state.userInfo.role == 2 && 
             this.isJettyAssigned();
    },
    isJettyAssigned() {
      return this.createdtable.status == 2 && this.createdtable.loadingType == null
    },
    isBooked() {
      return this.createdtable.status == 2 && this.createdtable.loadingType < 4
    }
  },
};
</script>

<style scoped lang="scss">
.bragingContainer {
  height: 100%;
  overflow-y: auto;
  .left {
    background: #ffffff;
    box-shadow: 0px 4px 4px 1px rgba(196, 196, 196, 0.1);
    border-radius: 2px 2px 0px 0px;
  }

  .textBtn {
    color: #2e5efd;
    text-decoration: underline;
  }
}

::v-deep .el-tabs__nav-wrap::after {
  background: transparent;
}

::v-deep .el-tabs__header {
  background: white;
  padding: 6px 22px;
  margin: 0;
}

::v-deep .el-tabs__item {
  font-size: 15px;
  color: rgba(130, 136, 156, 0.6);
}

::v-deep .el-tabs__item.is-active {
  color: $primaryColor;
  font-weight: bold;
}

::v-deep .el-tabs__item {
  padding: 0 15px;
}
</style>
