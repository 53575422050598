<template>
  <div class="form p-12">
    <div class="flex flex-wrap items-center">
      This Declaration of Security is valid from<span style="color:red">*</span>
      <div class="m-2">
        <el-date-picker v-model="dataForm.from" :disabled="disabled"   size="mini" type="datetime"></el-date-picker>
      </div>
      until<span style="color:red">*</span>
      <div class="m-2">
        <el-input v-model="dataForm.until" :disabled="true" size="mini"></el-input>
      </div>
      for the following activities<span style="color:red">*</span>:
      <div class="m-2">
        <el-select v-model="dataForm.activities" :disabled="disabled" size="mini">
          <el-option v-for="item in followingActivitieOptions" :key="item.key" :value="item.key" :label="item.label"/>
        </el-select>
      </div>
      under the following activities:
    </div>
    <div class="item mt-4">
      <div class="label fs-12 mb-4"><span style="color:red">*</span>Security level(s) for the ship:</div>
      <el-radio-group class="mb-4" v-model="dataForm.ship" :disabled="disabled">
        <el-radio label="1">1</el-radio>
        <el-radio label="2">2</el-radio>
        <el-radio label="3">3</el-radio>
      </el-radio-group>
    </div>
    <div class="item">
      <div class="label fs-12 mb-4"><span style="color:red">*</span>Security level(s) for the port facility:</div>
      <el-radio-group v-model="dataForm.facility" class="mb-4" :disabled="disabled">
        <el-radio label="1">1</el-radio>
        <el-radio label="2">2</el-radio>
        <el-radio label="3">3</el-radio>
      </el-radio-group>
    </div>
    <div class="mt-6">
      The port facility and ship agree to the following security measures and responsibilities to ensure compliance with
      the requirements of Part A of the International Code for the Security of Ships and of Port Facilities.
    </div>

    <div class="mt-4">
      <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
      <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
    </div>

    <el-table :data="deduplicatedList" class="mt-4">
      <el-table-column label="Activity" min-width="300" prop="formDescription" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="color:red">*</span>{{scope.row.formDescription}}
        </template>
      </el-table-column>
      <el-table-column label="The port facility" width="300">
        <template slot-scope="scope">
          <el-radio-group v-model="scope.row.checkFacility" :disabled="disabled||$store.state.userInfo.role!=='11'">
            <el-radio label="1">Yes</el-radio>
            <el-radio label="2">No</el-radio>
            <el-radio label="3">N.A</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="The ship" width="300">
        <template slot-scope="scope">
          <el-radio-group v-model="scope.row.checkShip" :disabled="disabled||$store.state.userInfo.role!=='8'">
            <el-radio label="1">Yes</el-radio>
            <el-radio label="2">No</el-radio>
            <el-radio label="3">N.A</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column label="Remarks" width="300">
        <template slot-scope="scope">
          <el-input v-model="scope.row.remarks" :disabled="disabled" size="mini"></el-input>
        </template>
      </el-table-column>
    </el-table>

    <div class="mt-4">
      <el-button v-if="checkedYes == false" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Check all</el-button>
      <el-button v-if="checkedYes == true" @click="checkedAllYes" size="mini" :disabled="checkedYesDisabled">Uncheck all</el-button>
    </div>
    
    <div class="mt-6">
      The signatories to this agreement certify that security measures and arrangements for both the port facility and the ship during the specified activities meet the provisions of chapter XI-2 and Part A of Code that
      will be implemented in accordance with the provisions already stipulated in their approved plan or the specific arrangements agreed to and set out in the attached annex.
    </div>
    <div class="flex flex-wrap items-center mt-6">
      Dated at<span style="color:red">*</span><div class="ml-2 mr-2">
      <el-date-picker v-model="dataForm.dated"  :disabled="disabled" format="yyyy-MM-dd HH:mm:ss" size="mini" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
    </div>
    <!--
    on the<span style="color:red">*</span><div class="ml-2 mr-2">
      <el-input v-model="dataForm.onThe"  :disabled="disabled" size="mini"></el-input>
    </div>
    -->
    </div>
    <div class="mt-8 black f-500">
      Contact Details (to be completed as appropriate)
    </div>
    <div style="font-weight: 300" class="black">
      (Indicate the telephone numbers or the radio channels of frequencies to be used)
    </div>
    <div class="flex flex-wrap mt-6">
      <div class="w-full md:w-1/2 ">
        <div class="f-600 mb-4">
          For the port facility:
        </div>
        <div class="item">
          <div class="label mb-2 fs-12 f-600">
            <span style="color:red">*</span>Port facility:
          </div>
          <div class="value">
            <el-input v-model="dataForm.portFacility" :disabled="disabled||$store.state.userInfo.role!=='11'" size="mini"></el-input>
          </div>
        </div>
        <div class="item">
          <div class="label mb-2 fs-12 f-600">
            <span style="color:red">*</span>Port Facility Security Officer:
          </div>
          <div class="value">
            <el-input v-model="dataForm.portOfficer" :disabled="disabled||$store.state.userInfo.role!=='11'" size="mini"></el-input>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div class="f-600 mb-4">
          For the ship:
        </div>
        <div class="item">
          <div class="label mb-2 fs-12 f-600">
            <span style="color:red">*</span>Master:
          </div>
          <div class="value">
            <el-input v-model="dataForm.master" :disabled="disabled||$store.state.userInfo.role!=='8'" size="mini"></el-input>
          </div>
        </div>
        <div class="item">
          <div class="label mb-2 fs-12 f-600">
            <span style="color:red">*</span>Ship Security Officer:
          </div>
          <div class="value">
            <el-input v-model="dataForm.shipOfficer" :disabled="disabled||$store.state.userInfo.role!=='8'" size="mini"></el-input>
          </div>
        </div>
        <div class="item">
          <div class="label mb-2 fs-12 f-600">
            <span style="color:red">*</span>Company:
          </div>
          <div class="value">
            <el-input v-model="dataForm.company" :disabled="disabled||$store.state.userInfo.role!=='8'" size="mini"></el-input>
          </div>
        </div>
        <div class="item">
          <div class="label mb-2 fs-12 f-600">
            <span style="color:red">*</span>Company Security Officer:
          </div>
          <div class="value">
            <el-input v-model="dataForm.companyOfficer" :disabled="disabled||$store.state.userInfo.role!=='8'" size="mini"></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 f-500" style="color:#4C565C ">
      Signature (Before Loading)
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";
import {data} from "autoprefixer";

export default {
  name: "DeclarationOfSecurity",
  props: {
    disabled: {
      type: Boolean
    },
    sign:{
      type:Object
    }
  },
  data() {
    return {
      dataForm:{

      },
      checkedYes: false,
      checkedYesDisabled: false,
      followingActivitieOptions: [
        {
          key: "Loading Operation",
          label: "Loading Operation",
        },
        {
          key: "Discharging Operation",
          label: "Discharging Operation",
        },
      ],
    }
  },

  computed: {
  deduplicatedList() {
    if (!this.dataForm.list) return [];
    const seen = new Set();
    return this.dataForm.list.filter(item => {
      const key = item.formDescription;
      if (seen.has(key)) {
        return false;
      }
      seen.add(key);
      return true;
    });
  }
  },

  created() {
    let role = this.getRole()
    this.checkedYesDisabled = this.disabled || ( role != '11' && role != '8' )
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 14
      }).then(res => {
          this.dataForm = {...res.data}
          this.dataForm.until = "DEPARTURE"
      })
    },
    getRole() {
      return this.$store.state.userInfo.role;
    },
    save() {
      this.dataForm.onThe = " "
      const data = {
        ...this.dataForm,
        list:this.dataForm.list.map(item=>{
          return {
            ...item,
            nominationId: this.params.id,
          }
        }),
        nominationId: this.params.id,
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    checkedTanker() {
      this.dataForm.list.map(item => {
        if( item.checkShip != '2' && item.checkShip != '3' ) {
          item.checkShip = this.checkedYes ? '1' : null
        }
      })
    },
    checkedTerminal() {
      this.dataForm.list.map(item => {
        if( item.checkFacility != '2' && item.checkFacility != '3' ) {
          item.checkFacility = this.checkedYes ? '1' : null
        }
      })
    },
    checkedAllYes() {
      if( this.checkedYesDisabled != true ) {
        this.checkedYes = ! this.checkedYes
        let role = this.getRole()
        if( role ==='11' ) {
          this.checkedTerminal();
        } else if( role ==='8' ) {
          this.checkedTanker();
        }
      }
    },
  }
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
  background-color: #fff;
}

.item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label {
    width: 200px;
    margin-bottom: 1rem;
  }
  .el-radio-group{
    width: 300px;
    margin-bottom: 1rem;
  }
  .value{
    margin-bottom: 1rem;
  }
}
</style>
