<template>
  <div class="form p-12">
    <div class="p-2 tank">
      <div class="f-500 black">
        Port
      </div>
      <el-table :data="port" :header-cell-class-name="headerClass" class="mt-4">
        <el-table-column align="center" label="Tank" prop="formItem" width="80"></el-table-column>
        <el-table-column label="Product">
          <template slot-scope="scope">
            <el-autocomplete v-model="scope.row.product" :disabled="disabled" size="mini" :fetch-suggestions="querySearchPortAsync">
              <template #default="{ item }">
                <div v-if="item.old" class="flex justify-between">
                  <div class="value">{{ item.value }}</div>
                  <div>
                    <i class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="deleteProduct( scope.$index, item.data )"></i>
                  </div>
                </div>
                <div v-else class="flex justify-between" @click="addMoreProduct(item.value)">
                  <div class="value">{{ item.value }}</div>
                  <div>
                    <el-button plain type="primary" style="padding:0; border-width: 0px; border-radius: 25px">
                      <i class="el-icon-circle-plus cursor-pointer fs-15"></i>
                    </el-button>
                  </div>
                </div>
              </template>
            </el-autocomplete>
          </template>
        </el-table-column>
        <el-table-column label="Before Loading" align="center">
          <el-table-column label="Ullage">
            <template slot-scope="scope">
              <el-input v-model="scope.row.ullage"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Gross Volume">
            <template slot-scope="scope">
              <el-input v-model="scope.row.volume"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Temp">
            <template slot-scope="scope">
              <el-input v-model="scope.row.temp"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Water">
            <template slot-scope="scope">
              <el-input v-model="scope.row.water"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" label="" width="40">
          <template slot="header">
            <i class="el-icon-delete fs-15"></i>
          </template>
          <template slot-scope="scope">
            <i v-if="disabled == false" class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="clearItem(scope.$index, 'port')"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt-4 p-2 tank">
      <div class="f-500 black flex justify-between">
        <div class="flex">Center</div>
        <div class="flex pr-1 pt-1">
          <svg v-if="showCenter" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="f-500 black w-4 h-4"
            @click="showCenter = false"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="f-500 black w-4 h-4"
            @click="showCenter = true"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      </div>
      <el-table v-if="showCenter" :data="center" :header-cell-class-name="headerClass" class="mt-4">
        <el-table-column align="center" label="Tank" prop="formItem" width="80"></el-table-column>
        <el-table-column label="Product">
          <template slot-scope="scope">
            <el-autocomplete v-model="scope.row.product" :disabled="disabled" size="mini" :fetch-suggestions="querySearchCenterAsync">
              <template #default="{ item }">
                <div v-if="item.old" class="flex justify-between">
                  <div class="value">{{ item.value }}</div>
                  <div>
                    <i class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="deleteProduct( scope.$index, item.data )"></i>
                  </div>
                </div>
                <div v-else class="flex justify-between" @click="addMoreProduct(item.value)">
                  <div class="value">{{ item.value }}</div>
                  <div>
                    <el-button plain type="primary" style="padding:0; border-width: 0px; border-radius: 25px">
                      <i class="el-icon-circle-plus cursor-pointer fs-15"></i>
                    </el-button>
                  </div>
                </div>
              </template>
            </el-autocomplete>
          </template>
        </el-table-column>
        <el-table-column label="Before Loading" align="center">
          <el-table-column label="Ullage">
            <template slot-scope="scope">
              <el-input v-model="scope.row.ullage"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Gross Volume">
            <template slot-scope="scope">
              <el-input v-model="scope.row.volume"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Temp">
            <template slot-scope="scope">
              <el-input v-model="scope.row.temp"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Water">
            <template slot-scope="scope">
              <el-input v-model="scope.row.water"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" label="" width="40">
          <template slot="header">
            <i class="el-icon-delete fs-15"></i>
          </template>
          <template slot-scope="scope">
            <i v-if="disabled == false" class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="clearItem(scope.$index, 'center')"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="mt-4 p-2 tank">
      <div class="f-500 black">
        Starboard
      </div>
      <el-table :data="starboard" :header-cell-class-name="headerClass" class="mt-4">
        <el-table-column align="center" label="Tank" prop="formItem" width="80"></el-table-column>
        <el-table-column label="Product">
          <template slot-scope="scope">
            <el-autocomplete v-model="scope.row.product" :disabled="disabled" size="mini" :fetch-suggestions="querySearchStarboardAsync">
              <template #default="{ item }">
                <div v-if="item.old" class="flex justify-between">
                  <div class="value">{{ item.value }}</div>
                  <div>
                    <i class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="deleteProduct( scope.$index, item.data )"></i>
                  </div>
                </div>
                <div v-else class="flex justify-between" @click="addMoreProduct(item.value)">
                  <div class="value">{{ item.value }}</div>
                  <div>
                    <el-button plain type="primary" style="padding:0; border-width: 0px; border-radius: 25px">
                      <i class="el-icon-circle-plus cursor-pointer fs-15"></i>
                    </el-button>
                  </div>
                </div>
              </template>
            </el-autocomplete>
          </template>
        </el-table-column>
        <el-table-column label="Before Loading" align="center">
          <el-table-column label="Ullage">
            <template slot-scope="scope">
              <el-input v-model="scope.row.ullage"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Gross Volume">
            <template slot-scope="scope">
              <el-input v-model="scope.row.volume"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Temp">
            <template slot-scope="scope">
              <el-input v-model="scope.row.temp"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Water">
            <template slot-scope="scope">
              <el-input v-model="scope.row.water"  :disabled="disabled" size="mini" type="number"></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column align="center" label="" width="40">
          <template slot="header">
            <i class="el-icon-delete fs-15"></i>
          </template>
          <template slot-scope="scope">
            <i v-if="disabled == false" class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="clearItem(scope.$index, 'starboard')"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="f-500 black mt-8">
      Draft
    </div>
    <div class="f-500 fs-13 mt-4 black">
      Before Loading / Discharging
    </div>
    <div class="item mt-4">
      <div class="fs-12 f-600 label"><span style="color:red">*</span>Forward:</div>
      <div class="value">
        <el-input v-model="dataForm.forward" :disabled="disabled" size="mini" type="number">
          <template slot="append">
            <el-button>Metre</el-button>
          </template>
        </el-input>
      </div>
    </div>
    <div class="item mt-4">
      <div class="fs-12 f-600 label"><span style="color:red">*</span>Aft:</div>
      <div class="value">
        <el-input v-model="dataForm.aft" :disabled="disabled" size="mini" type="number">
          <template slot="append">
            <el-button>Metre</el-button>
          </template>
        </el-input>
      </div>
    </div>
    <div class="f-500 black mt-4 flex justify-between">
      <div><span style="color:red">*</span>Ship Figure</div>
      <!-- <el-button size="mini" type="primary" @click="dataForm.listItem.push({})" :disabled="disabled">Add</el-button> -->
    </div>
    <el-table :data="dataForm.listItem" class="mt-4">
      <el-table-column label="Product Name" min-width="200">
        <template slot-scope="scope">
          <el-autocomplete v-model="scope.row.name" :disabled="disabled" :fetch-suggestions="querySearchAllAsync">
            <template #default="{ item }">
              <div v-if="item.old" class="flex justify-between">
                <div class="value">{{ item.value }}</div>
                <div>
                  <i class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="deleteProduct( scope.$index, item.data )"></i>
                </div>
              </div>
              <div v-else class="flex justify-between" @click="addMoreProduct(item.value)">
                <div class="value">{{ item.value }}</div>
                <div>
                  <el-button plain type="primary" style="padding:0; border-width: 0px; border-radius: 25px">
                    <i class="el-icon-circle-plus cursor-pointer fs-15"></i>
                  </el-button>
                </div>
              </div>
            </template>
          </el-autocomplete>
        </template>
      </el-table-column>
      <el-table-column label="Gross Volume" min-width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.grossVolume" :disabled="disabled" type="number" ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Volume(Less Water)" min-width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.waterVolume" :disabled="disabled" type="number" ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Volume Correction Factor" min-width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.volumeCorrectionFactor" :disabled="disabled" type="number" ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Nett Volume" min-width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.nettVolume" :disabled="disabled" type="number" ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Density @ 15°C" min-width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.density" :disabled="disabled" type="number" ></el-input>
        </template>
      </el-table-column>
      <el-table-column label="Metric Tons" min-width="200">
        <template slot-scope="scope">
          <el-input v-model="scope.row.metricTons" :disabled="disabled"  type="number" ></el-input>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <i v-if="dataForm.listItem.length>1" class="el-icon-circle-close cursor-pointer fs-15" style="color: red" @click="delListItemProduct(scope.$index)"></i>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div style="color: red;" class="mt-4 mb-4 fs-12">
      *Please fill in at least 1 product and its qualities.
    </div> -->
    <div class="f-500 black mt-8">
      Remarks
    </div>
    <div class="item mt-4">
      <div class="fs-12 f-600" style="width: 100px">Before Loading:</div>
      <div class="value pl-5 w-full">
        <el-input v-model="dataForm.beforeRemarks" :maxlength="600" :rows="3" :disabled="disabled" show-word-limit type="textarea">
        </el-input>
      </div>
    </div>
    <div class="pb-4"></div>
    <div class="mt-4 f-500" style="color:#4C565C ">
      Signature
    </div>
    <div class="flex flex-wrap w-full xl:w-1/2 mt-4 justify-between">
      <div class="w-full sm:w-1/2 ">
        <div class="f-600">Terminal Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.terminal.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.terminal.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.terminal.eSignature" :src="sign.terminal.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
      <div class="w-full mt-4 sm:w-1/2 sm:mt-0">
        <div class="f-600">Barge Representative</div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Name:</span>
          <span class="ml-2">{{sign.barge.name}}</span>
        </div>
        <div class="mt-4 f-600" style="font-size: 12px">
          <span>Date and Time:</span>
          <span class="ml-2">{{sign.barge.createTime}}</span>
        </div>
        <div class="mt-4 f-600 flex" style="font-size: 12px">
          <span>Signature:</span>
          <img v-if="sign.barge.eSignature" :src="sign.barge.eSignature" class="ml-2" style="width: 100px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {saveOrUpdateForms, selectFromById} from "@/services/form";
import { delUllageReport } from "@/services/user";
import {productAutocomplete} from "@/utils/tools"

export default {
  name: "UllageReport",
  props:{
    disabled:{
      type:Boolean
    },
    sign:{
      type:Object
     },
     modifyReason:{
      type: String,
    }
  },
  data() {
    return {
      dataForm: {},
      port: [],
      center: [],
      starboard: [],
      params: {},
      autocomplete: null,
      showCenter: false,
    }
  },
  mounted() {
    if (this.$route.query) {
      this.params = this.$route.query
      this.getDetail()
    }
  },
  created() {
    this.autocomplete = productAutocomplete()
  },
  methods: {
    delListItemProduct(e){
      this.dataForm.listItem.splice(e,1)
    },
    save() {
      this.port.map( item => {
        if( item.product ) item.product = item.product.trim()
      })
      this.center.map( item => {
        if( item.product ) item.product = item.product.trim()
      })
      this.starboard.map( item => {
        if( item.product ) item.product = item.product.trim()
      })
      const data = {
        ...this.dataForm,
        list: this.port.concat(this.center).concat(this.starboard).map(item => {
          return item.product || item.ullage || item.volume || item.temp || item.water ? {
            ...item,
            nominationId: this.params.id,
          } : null
        }).filter(x => x != null),
        listItem:this.dataForm.listItem.map(item=>{
          return {
            ...item,
            nominationId: this.params.id,
          }
        }),
        nominationId: this.params.id,
        reasonToModifyUllageReport: this.modifyReason
      }
      saveOrUpdateForms(data).then(res=>{
        this.getDetail()
        this.$emit('update')
        this.$message.success('Save Success')
      }).catch(()=>{
        this.$emit('update')
      })
    },
    headerClass({column}) {
      const label = column.label
      if (label === 'Ullage' || label === 'Gross Volume' || label === 'Temp' || label === 'Water') {
        return 'blue'
      }
    },
    getDetail() {
      selectFromById({
        nId: this.params.id,
        fId: 13
      }).then(res => {
        this.dataForm = {
          ...res.data,
          listItem:res.data.listItem&&res.data.listItem.length>0?res.data.listItem:[{}]
        }
        this.port = res.data.list.filter(item => item.descr === 'Port')
        this.center = res.data.list.filter(item => item.descr === 'Center')
        this.starboard = res.data.list.filter(item => item.descr === 'Starboard')
      })
    },
    clearData( item ) {
      item.product = ''
      item.ullage = ''
      item.volume = ''
      item.temp = ''
      item.water = ''
      item.id = null
      item.nominationId = null
      return item
    },
    clearItem( index, item ) {
      let nId = 0
      let ullageId = 0
      let temp = null

      if( item == 'port') {
        temp = this.port[index]
      } else if( item == 'center') {
        temp = this.center[index]
      } else if( item == 'starboard') {
        temp = this.starboard[index]
      }

      nId = temp.nominationId
      ullageId = temp.id
      temp = this.clearData( temp )

      if( item == 'port') {
        this.port[index] = temp
      } else if( item == 'center') {
        this.center[index] = temp
      } else if( item == 'starboard') {
        this.starboard[index] = temp
      }

      if( nId && ullageId ) {
        delUllageReport(nId, ullageId)
      }
    },
    async querySearchPortAsync(queryString, cb) {
      this.autocomplete.port()
      await this.autocomplete.search( queryString, cb )
    },
    async querySearchCenterAsync(queryString, cb) {
      this.autocomplete.center()
      await this.autocomplete.search( queryString, cb )
    },
    async querySearchStarboardAsync(queryString, cb) {
      this.autocomplete.starboard()
      await this.autocomplete.search( queryString, cb )
    }, 
    async querySearchAllAsync(queryString, cb) {
      this.autocomplete.all()
      await this.autocomplete.search( queryString, cb )
    }, 
    addMoreProduct(name) {
      this.autocomplete.add( name )
    },
    async deleteProduct( index, data ) {
      await this.autocomplete.delete( data.id, data.name, ( query ) => {
        let item = this.autocomplete.target()
        if( item=='Port' ) {
          this.port[index].product = query
        } else if( item=='Center' ) {
          this.center[index].product = query
        } else if( item=='Starboard' ) {
          this.starboard[index].product = query
        } else {
          this.dataForm.listItem[index].name = query
        }
      })
    },
  }
}
</script>


<style scoped lang="scss">
.form {
  font-size: 15px;
  color: #82889C;
  background-color: #fff;
}

.item {
  display: flex;
  align-items: center;

  .label {
    width: 80px;
  }

  .value {
    max-width: calc(100% - 50px);
  }
}

.tank {
  border-radius: 10px;
  border: 1px solid #CCE1FD;
}

::v-deep .blue {
  background-color: #E6FCFE !important;
  border-right: 1px solid #CCE1FD !important;

  .cell {
    background-color: #E6FCFE !important;
  }
}

::v-deep .el-input-group__append {
  background-color: #82889C;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
</style>
